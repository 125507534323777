html, body {
  font-family: Roboto, Muli, Helvetica Neue, Arial, sans-serif;
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}
